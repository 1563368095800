import { ParsedUrlQuery } from 'querystring'

import { StoreType } from '@viewlio/config/types'
import { viewlioConfig, VIEWLIO_ENV } from '@viewlio/config/viewlioConfig'
import { paths } from '@viewlio/config/viewlioConfig/paths'
import { FlashMessage, JuulStore, LineItem } from '@viewlio/types'
import {
  ApplyPromotionCodeResponse,
  SubscriptionQuoteResponse,
  PlanErrorsResponse,
  CommitmentRewardDisclaimerResponse,
  UpdateCartResponse,
  UpdateSubscriptionPodsResponse,
  UpdatePodsSubscriptionRequest,
  CreateInauthenticPodReportRequest,
  ProfileResponse,
  GetOrdersContract,
  GetOrdersParams,
  RegisteredProductsResponse,
  WarrantySkusResponse,
  CancelSubscriptionRequestBody,
  SubscriptionResponse,
  ValidateAddressResponse,
  CommitmentResponse,
  OrderResponse,
  SplitTreatmentResponse,
  UpdateSubscriptionPaymentMethodRequest,
  UpdateSubscriptionAdyenPaymentMethodRequest,
  BraintreeClientTokenResponse,
  PaymentMethodsResponse,
  SendCodeResponse,
  VerifyCodeResponse,
  SupportCaseResponse,
  PasswordUserInfoResponse,
  UpdatePasswordResponse,
  CreateNewPasswordResponse,
  AgeVerificationResponse,
  UpdateAgeVerificationRequest,
  StartNextLevelAgeVerificationRequest,
  VeriffSessionStatusResponse,
  ApplyPromotionRequest,
  ApplyPromotionResponse,
  UpdateOneTimeAddOnsSubscriptionRequest,
  SaveAdyenCreditCardRequest,
  SaveAdyenCreditCardResponse,
  PromotionQuoteRequest,
  PromotionQuoteResponse,
  CsrfTokenResponse,
  SuppressSegmentResponse,
  AccountRecoverySendCodeRequest,
  AccountRecoveryVerifyCodeRequest,
  AccountRecoverySendCodeResponse,
  AccountRecoveryVerifyCodeResponse,
  PhoneVerificationSendCodeRequest,
  PhoneVerificationVerifyCodeRequest,
  PhoneVerificationSupportCaseRequest,
  PhoneVerificationRenewSendCodeRequest,
  PhoneVerificationRenewVerifyCodeRequest,
  PhoneVerificationRenewSendCodeResponse,
  PhoneVerificationRenewVerifyCodeResponse,
  ApplyPromotionSubscriptionResponse,
  EstimateAgeRequest,
  SkipAgeEstimationRequest,
  EstimateAgeResponse,
  SkipAgeEstimationResponse,
  PurchaseLocationsResponse,
  ProductRegistrationRequest,
  ProductRegistrationResponse,
  VerifySocureRequest,
  VerifySocureResponse,
  WarrantyIssuesResponse,
  PostWarrantyClaimRequest,
  PostWarrantyClaimResponse,
  GetUserAddressesResponse,
  PostUserAddressResponse,
  VeriffSessionResponse,
  ArcoscanSessionResponse,
  ArcoscanSessionStatusResponse,
  TraceabilityReportRequest,
  TraceabilityReportResponse,
  DeliveryAddressRequest,
  DeliveryAddressResponse,
  ValidateCommitmentInstallmentLimitRequest,
  ValidateCommitmentInstallmentLimitResponse,
  GopuffMultipassResponse,
  GetGlobalPagesResponse,
  DoorsRequest,
  DoorsResponse,
  GetSecurityQuestionsResponse,
  SetSecurityQuestionRequest,
  SetSecurityQuestionResponse,
  BusinessStoreResponse,
  GetCouponsForGeolocationResponse,
  GetDoorsForCouponResponse,
  CouponsDoorsRequest,
  FetchAgeVerificationVeratadTokenResponse,
  ShopperResponse,
} from '@viewlio/types/api/juulio'
import {
  PersonalPromotionCodeResponse,
} from '@viewlio/types/api/juulio/PersonalPromotionCodeResponse'
import { SkipAgeEstimationBasedOnDOBRequest } from '@viewlio/types/api/juulio/SkipAgeEstimationBasedOnDOBRequest'
import { SplitTreatmentKey } from '@viewlio/types/api/juulio/SplitTreatmentResponse'
import { UpdateSubscriptionRenewalDateResponse } from '@viewlio/types/api/juulio/UpdateSubscriptionRenewalDateResponse'
import { Subscription } from '@viewlio/types/autoship'
import {
  ProductCatalogueRaw,
  CartWithCartLineItems,
  ActiveSubscriptionModalData,
  SubscriptionAddress,
  ValidationAddress,
  SubscriptionPlan,
} from '@viewlio/types/ecommerce'
import { FlashMessageTypes } from '@viewlio/types/FlashMessage'
import Jsona from 'jsona'

import { bugsnagClient } from 'lib/bugsnag'
import { useGlobalStore } from 'stores'
import { getBugsnagError } from 'utils/bugsnag/getBugsnagError'
import { camelizeKeys, decamelizeKeys } from 'utils/caseConversion'
import { getStoreByTypeAndLocale } from 'utils/locales/getStoreByTypeAndLocale'
import { parse, stringify } from 'utils/urls/querystring'

const { apiKeys } = viewlioConfig

const defaultLocale = () =>
  useGlobalStore.getState().locale

const defaultStoreType = () =>
  useGlobalStore.getState().storeType

type RequestWithoutBody = {
  body?: never
  method?: 'GET'
}

type RequestWithBody = {
  body?: any
  method: 'PUT' | 'POST' | 'PATCH' | 'DELETE'
}

type ErrorHandlers = Record<number | 'fatalError', (errorResponse: Response, throwError?: boolean) => void>

const parseJSONAPIPayload = <T>(payload) => {
  if (payload?.data) {
    return new Jsona().deserialize(payload) as Promise<T>
  } else {
    return null
  }
}

const handleError = (errorResponse: Response, throwError = false) => {
  const handleThrowError = async (response: Response) => {
    // when server returns 500 or fails without a response, return generic error
    if (response.status >= 500 || typeof response.json === 'undefined') {
      throw {
        error: 'common.errors.default',
        statusCode: response.status,
      }
    }

    const responseJSON = await response.json()

    if (responseJSON?.errors?.[0]?.detail) {
      throw {
        error: responseJSON.errors[0].detail,
        statusCode: response.status,
      }
    }

    throw {
      ...responseJSON,
      statusCode: response.status,
    }
  }

  return throwError ?
    handleThrowError(errorResponse) :
    errorResponse as Response
}

const defaultErrorHandlers = {
  401: (errorResponse: Response, throwError = false) => {
    window.location.href = paths.signin

    return handleError(errorResponse, throwError)
  },
  fatalError: (errorResponse: Response, throwError = false) => {
    // eslint-disable-next-line max-len
    // https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
    bugsnagClient.notify(
      getBugsnagError(errorResponse),
      (event) => {
        event.severity = 'error'
      },
    )

    return handleError(errorResponse, throwError)
  },
}

// Use the AWS WAF Integration fetch wrapper
// to automatically request and set a WAF token
// before every request.
const getFetchAPI = () => {
  if (typeof window !== 'undefined' && window.AwsWafIntegration?.fetch) {
    return window.AwsWafIntegration.fetch
  }

  return fetch
}

type APIRequestConfig = {
  autoCamelizeKeys?: boolean
  errorHandlers?: ErrorHandlers
  locale?: string
  relativePath: string
  signal?: AbortSignal | null
  storeType?: StoreType
  throwError?: boolean
} & (RequestWithoutBody | RequestWithBody)

export const fetchFromApi = async ({
  relativePath,
  method = 'GET',
  body = undefined,
  autoCamelizeKeys = true,
  locale = defaultLocale(),
  storeType = defaultStoreType(),
  throwError = false,
  errorHandlers = defaultErrorHandlers,
  signal = null,
}: APIRequestConfig): Promise<any> => {
  if (typeof locale !== 'string') {
    throw new Error('API request was called while locale is not properly set on global store')
  }

  const [_storeCode, store] = getStoreByTypeAndLocale(storeType, locale)
  const url = new URL(relativePath, store.baseUrl[VIEWLIO_ENV])

  const useAbsoluteUrl = typeof window === 'undefined' ||
    viewlioConfig.xhrAbsoluteUrlEnvironments.includes(VIEWLIO_ENV)

  const stringifyUrl = (url: URL) =>
    useAbsoluteUrl ? url.toString() : `${url.pathname}${url.search}`

  try {
    const query = parse(url.search)

    if (store.locales.length > 1) {
      query.locale = locale
    }

    url.search = stringify(query, { encodeValuesOnly: true })

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Juulio-Skip-Csrf': 'Skip',
      'X-Requested-With': 'XMLHttpRequest',
    }

    if (typeof window === 'undefined') {
      headers['x-viewlio-edge-id'] = apiKeys?.cloudfrontEdgeId ?? undefined
      headers['X-Juul-Runtime'] = 'viewlio'
      headers['X-JUULCUSTOM'] = 'loadtest'
    }

    const fetch = getFetchAPI()

    const response = await fetch(
      stringifyUrl(url),
      {
        body: JSON.stringify(body),
        credentials: 'include',
        headers,
        method,
        signal: signal,
      },
    )

    if (response.ok) {
      const json = await response.json()
      return autoCamelizeKeys ? camelizeKeys(json) : json
    } else {
      throw response
    }

  } catch (errorResponse) {
    if (typeof window === 'undefined') {
      const errorLog = {
        body: null,
        headers: null,
        status: null,
        text: null,
        url: stringifyUrl(url),
        viewlioEnv: VIEWLIO_ENV,
      }

      if (errorResponse?.status) {
        errorLog.headers = errorResponse.headers
        errorLog.status = errorResponse.status
        errorLog.text = errorResponse.text()
      }

      // eslint-disable-next-line no-console
      console.log('\nJuulio API request error:', JSON.stringify(errorLog, null, 2))

      // In server environments, as we currently only do SSG (static) rendering,
      // we want to throw an unhandled exception for failed network requests, to
      // cause our build process (either initial build or regeneration) to exit
      // with a non-zero code. This will cause the build to fail, and will leave
      // previously cached content in place, until we have a successful build.
      throw new Error(errorResponse)
    } else {
      if (typeof errorResponse?.status === 'number') {
        if (errorResponse.status >= 500) {
          return errorHandlers['fatalError'](errorResponse, throwError)
        }

        if (errorHandlers[errorResponse.status]) {
          return errorHandlers[errorResponse.status](errorResponse, throwError)
        }
      }

      return handleError(errorResponse, throwError)
    }
  }
}

export const getStore = async (storeCode: string): Promise<JuulStore> =>
  fetchFromApi({
    relativePath: `/api/v1/stores/${storeCode.toLowerCase()}`,
  })

export const getProductCatalogue = async (
  locale?: string,
): Promise<ProductCatalogueRaw> => {
  const response = await fetchFromApi({
    locale,
    relativePath: '/api/v4/product_catalog',
  })

  return parseJSONAPIPayload<ProductCatalogueRaw>(response)
}

export const getCart = async (): Promise<{
  activeSubscriptionModalData: ActiveSubscriptionModalData
  cart: CartWithCartLineItems
  errorFlash: FlashMessage
  promotionFlashes: Partial<Record<FlashMessageTypes, string>>
  subscriptionPlan: SubscriptionPlan
}> =>
  fetchFromApi({ relativePath: '/api/v3/cart' })

export const getOrders = async (
  params?: GetOrdersParams,
): Promise<GetOrdersContract> => {
  const limit = params?.limit || 10
  const page = params?.page || 1
  const filter = stringify(
    { filter: { state: params?.filter || [] } },
    { encode: false },
  )

  return fetchFromApi({
    relativePath: `/api/v2/orders?limit=${limit}&page=${page}&${filter}`,
  })
}

export const getOrder = async (id: string): Promise<OrderResponse> =>
  fetchFromApi({ relativePath: `/api/v2/orders/${id}` })

export const cancelOrder =
  async (orderNumber: string): Promise<OrderResponse> =>
    fetchFromApi({
      method: 'POST',
      relativePath: `/api/v2/orders/${orderNumber}/cancel`,
      throwError: true,
    })

export const getShopper = async (): Promise<ShopperResponse> =>
  fetchFromApi({ relativePath: '/api/v1/shopper' })

export const updateShopper = async (body: Record<string, unknown>) => {
  fetchFromApi({
    body,
    method: 'PUT',
    relativePath: '/api/v1/shopper',
  })
}

const redirectQueryString = (redirect: string) =>
  redirect ? `?${stringify({ redirect })}` : ''

type SignIn = (
  body: { email: string, password: string },
  redirect?: string
) => Promise<any>

export const signIn: SignIn = async (body, redirect) => {
  const params = { spreeUser: body }
  const query = redirectQueryString(redirect)

  return fetchFromApi({
    body: decamelizeKeys(params),
    method: 'POST',
    relativePath: `/api/v1/user_sessions${query}`,
  })
}

type SignUp = (
  body: Record<string, unknown>,
  redirect?: string
) => Promise<any>

export const signUp: SignUp = async (body, redirect) => {
  const query = redirectQueryString(redirect)

  return fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: `/api/v1/user_registrations${query}`,
  })
}

export const getPersonalizedContent = async (params: unknown) =>
  fetchFromApi({
    body: params,
    method: 'POST',
    relativePath: '/api/v1/content/personalized',
  })

export const getSubscribablePods = async () =>
  // this request can be made from a logged out state and only returns
  // pods that could be subscribable
  fetchFromApi({ relativePath: '/api/v1/subscribable_pods' })

//TODO: SOLVE CSRF
export const createSubscriptionQuote = async (
  addOns: Record<string, unknown>,
  oneTimeAddOns: Record<string, unknown>,
): Promise<SubscriptionQuoteResponse> =>
  fetchFromApi({
    body: decamelizeKeys({ add_ons: addOns, one_time_add_ons: oneTimeAddOns }),
    method: 'POST',
    relativePath: '/api/v1/subscription_quotes',
  })

export const updateSubscription = async (
  uuid: string,
  changedFields: Record<string, unknown>,
) =>
  fetchFromApi({
    body: {
      uuid: uuid,
      ...changedFields,
    },
    method: 'PATCH',
    relativePath: '/api/v1/subscription',
  })

export const createCommitmentRewardDisclaimer = async (
  lineItems: Array<unknown>,
): Promise<CommitmentRewardDisclaimerResponse> =>
  fetchFromApi({
    body: decamelizeKeys({ lineItems }),
    method: 'POST',
    relativePath: '/api/v1/subscription_builder/commitment_reward_disclaimer',
  })

//TODO: SOLVE CSRF
export const getAutoshipBuilderErrorState = async (
  quantity: number,
  quantityType: 'pods' | 'packs',
): Promise<PlanErrorsResponse> => {
  const args: {
    packsQuantity?: number
    podsQuantity?: number
  } = {}
  if (quantityType === 'pods') args.podsQuantity = quantity
  if (quantityType === 'packs') args.packsQuantity = quantity

  return fetchFromApi({
    body: args,
    method: 'POST',
    relativePath: '/api/v2/plan_errors',
  })
}

export const addToCart = async (
  body: {
    lineItems?: LineItem[]
    promoCode?: string
  },
): Promise<UpdateCartResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v3/cart/line_items',
  })

export const updateCart = async (
  body: {
    lineItems?: LineItem[]
    membershipOrder?: boolean
  },
): Promise<CartWithCartLineItems> => {
  if ('lineItems' in body) {
    body.lineItems = body.lineItems.map(lineItem => ({
      offeringId: lineItem.offeringId,
      quantity: lineItem.quantity,
      sku: lineItem.sku,
    }))
  }

  return fetchFromApi({
    body: decamelizeKeys(body),
    method: 'PUT',
    relativePath: '/api/v3/cart',
    throwError: true,
  })
}

export const updateCartItem = async (
  lineItem: LineItem,
  promoCode?: string,
): Promise<UpdateCartResponse> =>
  fetchFromApi({
    body: decamelizeKeys({ ...lineItem, promoCode }),
    method: 'PUT',
    relativePath: `/api/v3/cart/line_items/${lineItem.sku}`,
  })

export const setDeliveryZipcode = async (zipcode: string) =>
  fetchFromApi({
    method: 'PUT',
    relativePath: `/api/v1/delivery_zipcode?zipcode=${zipcode}`,
    throwError: true,
  })

export const getTrackingEvents = async (id: string) =>
  fetchFromApi({
    relativePath: `/api/v2/tracking_events/${id}`,
    throwError: true,
  })

export const getAlternateLangs = async () =>
  fetchFromApi({
    autoCamelizeKeys: false,
    relativePath: '/api/v1/content/meta_alternate_langs',
  })

export const fetchSubscription = async (): Promise<Subscription> | null => {
  const response = await fetchFromApi({ relativePath: '/api/v5/subscription' })

  return parseJSONAPIPayload<Subscription>(response)
}

export const fetchCommitment = async (
  subscriptionId: string,
): Promise<CommitmentResponse> =>
  fetchFromApi({
    relativePath: `/api/v4/subscription/${subscriptionId}/commitment`,
  })

export const validateCommitmentInstallmentLimit = async (
  body: ValidateCommitmentInstallmentLimitRequest,
): Promise<ValidateCommitmentInstallmentLimitResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v4/subscription/commitment/check_installment_limit',
    throwError: true,
  })

export const cancelSubscription = async (
  body: CancelSubscriptionRequestBody,
): Promise<Subscription> | null => {
  const response = await fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v4/subscription/cancel',
    throwError: true,
  })

  return parseJSONAPIPayload<Subscription>(response)
}

export const updatePodsSubscription = async (
  data: UpdatePodsSubscriptionRequest,
): Promise<UpdateSubscriptionPodsResponse> =>
  fetchFromApi({
    body: data,
    method: 'PATCH',
    relativePath: '/api/v1/subscription/pod',
    throwError: true,
  })

export const updateSubscriptionRenewalDate = async (body: {
  nextOrderDate: string
  uuid: string
}): Promise<UpdateSubscriptionRenewalDateResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'PATCH',
    relativePath: '/api/v1/subscription/renewal_date',
    throwError: true,
  })

export const applyPromotionCode = async (
  code: string,
): Promise<ApplyPromotionCodeResponse> =>
  fetchFromApi({
    body: { promotion: { code } },
    method: 'POST',
    relativePath: '/api/v1/promotions/apply',
  })

export const getPersonalPromotionCode =
  async (): Promise<PersonalPromotionCodeResponse> =>
    fetchFromApi({ relativePath: '/api/v1/promotions/personal_code' })

export const updateProfile =
  async (body: any): Promise<ProfileResponse> =>
    fetchFromApi({
      body: decamelizeKeys(body),
      method: 'PUT',
      relativePath: '/api/v2/profile',
      throwError: true,
    })

export const fetchUserZipcode = async () =>
  fetchFromApi({
    relativePath: '/api/v1/user_zipcodes',
    throwError: true,
  })

export const createInauthenticPodReport = async (
  data: CreateInauthenticPodReportRequest,
) =>
  fetchFromApi({
    body: data,
    method: 'POST',
    relativePath: '/api/inauthentic_pod_reports',
  })

export const getWarrantySkus =
  async (productType: string): Promise<WarrantySkusResponse> =>
    fetchFromApi({
      relativePath: `/api/warranty/skus/${productType}`,
      throwError: true,
    })

export const getWarrantyEligibleProducts = async (
  topic: 'accessory' | 'device',
): Promise<RegisteredProductsResponse> =>
  fetchFromApi({
    relativePath: `/api/v1/product_registrations/warranty_eligible_by_product/${topic}`,
    throwError: true,
  })

export const getWarrantyIssuesForProduct = async (
  claimWarrantySkuId: number,
): Promise<WarrantyIssuesResponse> =>
  fetchFromApi({
    relativePath: `/api/warranty/issues/${claimWarrantySkuId}`,
    throwError: true,
  })

export const postWarrantyClaim = async (
  body: PostWarrantyClaimRequest,
): Promise<PostWarrantyClaimResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/warranty/claims',
    throwError: true,
  })

export const getRegisteredProducts =
  async (): Promise<RegisteredProductsResponse> =>
    fetchFromApi({
      relativePath: '/api/v1/product_registrations',
      throwError: true,
    })

export const getUserAddresses = async (): Promise<GetUserAddressesResponse> =>
  fetchFromApi({
    relativePath: '/api/v1/user_addresses',
    throwError: true,
  })

export const postUserAddress = async (
  address: ValidationAddress,
  phone: string,
): Promise<PostUserAddressResponse> =>
  fetchFromApi({
    body: decamelizeKeys({
      ...address,
      phone,
    }),
    method: 'POST',
    relativePath: '/api/v1/user_addresses',
    throwError: true,
  })

export const validateAddress = async (
  addressFields: ValidationAddress,
  validateViaThirdParty = true,
): Promise<ValidateAddressResponse> =>
  fetchFromApi({
    body: decamelizeKeys({
      addressFields,
      validateViaThirdParty,
    }),
    method: 'POST',
    relativePath: '/api/address_validation',
    throwError: true,
  })

export const updateSubscriptionShippingAddress = async (
  uuid: string,
  changedFields: Partial<SubscriptionAddress>,
  updateBothAddresses: boolean,
): Promise<Subscription> | null => {
  const response = await fetchFromApi({
    body: decamelizeKeys({
      shippingAddress: changedFields,
      updateBothAddresses,
      uuid,
    }),
    method: 'PATCH',
    relativePath: '/api/v4/subscription/shipping_address',
    throwError: true,
  })

  return parseJSONAPIPayload<Subscription>(response)
}

export const updateSubscriptionBillingAddress = async (
  uuid: string,
  changedFields: Partial<SubscriptionAddress>,
  updateBothAddresses: boolean,
): Promise<Subscription> | null => {
  const response = await fetchFromApi({
    body: decamelizeKeys({
      billingAddress: changedFields,
      updateBothAddresses,
      uuid,
    }),
    method: 'PATCH',
    relativePath: '/api/v4/subscription/billing_address',
    throwError: true,
  })

  return parseJSONAPIPayload<Subscription>(response)
}

export const getSplitTreatment =
  async (treatment: SplitTreatmentKey): Promise<SplitTreatmentResponse> =>
    fetchFromApi({
      relativePath: `/api/v1/split?features[]=${treatment}`,
    })

export const getComplianceRestrictedStates =
  async (): Promise<string[]> =>
    fetchFromApi({
      relativePath: '/api/v1/subscription/compliance_restricted_states',
      throwError: true,
    })

export const fetchAvailablePaymentMethods =
  async (): Promise<PaymentMethodsResponse> =>
    fetchFromApi({
      relativePath: '/api/v1/payment_methods',
      throwError: true,
    })

export const fetchBraintreeClientToken =
  async (): Promise<BraintreeClientTokenResponse> =>
    fetchFromApi({
      method: 'POST',
      relativePath: '/solidus_paypal_braintree/client_token.json',
      throwError: true,
    })

export const updateSubscriptionPaymentMethod =
  async (
    uuid: string,
    changedFields: UpdateSubscriptionPaymentMethodRequest |
      UpdateSubscriptionAdyenPaymentMethodRequest,
  ): Promise<SubscriptionResponse> =>
    fetchFromApi({
      body: {
        ...decamelizeKeys(changedFields),
        uuid,
      },
      method: 'PATCH',
      relativePath: '/api/v1/subscription/payment_method',
      throwError: true,
    })

export const sendCode = async (
  body: PhoneVerificationSendCodeRequest,
): Promise<SendCodeResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v1/phone_verification/send_code',
    throwError: true,
  })

export const verifyCode = async (
  body: PhoneVerificationVerifyCodeRequest,
): Promise<VerifyCodeResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v1/phone_verification/verify_code',
    throwError: true,
  })

export const supportCase = async (
  body: PhoneVerificationSupportCaseRequest,
): Promise<SupportCaseResponse> =>
  fetchFromApi({
    body: { support_case: decamelizeKeys(body) },
    method: 'POST',
    relativePath: '/api/v1/phone_verification/support_case',
    throwError: true,
  })

export const fetchPasswordUserInfo = async (
  resetPasswordToken,
): Promise<PasswordUserInfoResponse> => {
  const body = decamelizeKeys({
    resetPasswordToken,
  })
  return fetchFromApi({
    body,
    method: 'POST',
    relativePath: '/api/v2/password/user_info',
    throwError: true,
  })
}

export const createNewPassword = async (
  email,
): Promise<CreateNewPasswordResponse> => {
  const body = decamelizeKeys({
    spreeUser: { email },
  })
  return fetchFromApi({
    body,
    method: 'POST',
    relativePath: '/api/v2/password',
    throwError: true,
  })
}

export const updatePassword = async (
  password,
  securityAnswer,
  resetPasswordToken,
): Promise<UpdatePasswordResponse> => {
  const body = decamelizeKeys({
    spreeUser: {
      password,
      resetPasswordToken,
      securityAnswer,
    },
  })
  return fetchFromApi({
    body,
    method: 'POST',
    relativePath: '/api/v2/password/update',
    throwError: true,
  })
}

export const getSecurityQuestions = async ():
  Promise<GetSecurityQuestionsResponse> =>
  fetchFromApi({
    method: 'GET',
    relativePath: '/api/v2/password/security_questions',
  })

export const setSecurityQuestion = async ({
  answer,
  currentPassword,
  key,
}: SetSecurityQuestionRequest['userSecurityQuestion']):
  Promise<SetSecurityQuestionResponse> => {
  const body = decamelizeKeys({
    userSecurityQuestion: {
      answer,
      currentPassword,
      key,
    },
  })

  return fetchFromApi({
    body,
    method: 'POST',
    relativePath: '/api/v2/password/security_questions',
    throwError: true,
  })
}

export const fetchAgeVerification =
  async (): Promise<AgeVerificationResponse | Response> =>
    fetchFromApi({
      method: 'GET',
      relativePath: '/api/v1/age_verification',
    })

export const updateAgeVerification = async (
  body: UpdateAgeVerificationRequest,
): Promise<AgeVerificationResponse | Response> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'PUT',
    relativePath: '/api/v1/age_verification',
  })

export const startNextLevelAgeVerification = async (
  body: StartNextLevelAgeVerificationRequest,
): Promise<AgeVerificationResponse | Response> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v1/age_verification/start_next_level',
  })

export const createBerbixTransaction = () => fetchFromApi({
  method: 'POST',
  relativePath: '/api/v1/berbix/transaction/create',
  throwError: true,
})

export const completeBerbixTransaction = () => fetchFromApi({
  method: 'POST',
  relativePath: '/api/v1/berbix/verification/complete',
  throwError: true,
})

export const startVeriffSession =
  (): Promise<VeriffSessionResponse> => fetchFromApi({
    method: 'POST',
    relativePath: '/api/v2/veriff/create',
    throwError: true,
  })

export const startVeriffAgeEstimationSession =
  (): Promise<VeriffSessionResponse> => fetchFromApi({
    method: 'POST',
    relativePath: '/api/v5/age_verification/age_estimation/session',
    throwError: true,
  })

export const getVeriffSessionStatus =
  (token: string): Promise<VeriffSessionStatusResponse> => fetchFromApi({
    method: 'GET',
    relativePath: `/api/v2/veriff/session_status?token=${token}`,
    throwError: true,
  })

export const getVeriffAgeEstimationSessionStatus =
  (token: string): Promise<VeriffSessionStatusResponse> => fetchFromApi({
    method: 'GET',
    relativePath: `/api/v5/age_verification/age_estimation/session_status?token=${token}`,
    throwError: true,
  })

export const startJumioNetverifyScan = (): Promise<{ iframeSrc: string }> =>
  fetchFromApi({
    method: 'GET',
    relativePath: '/api/v1/jumio_netverify/scans',
    throwError: true,
  })

export const postJumioError = (query: ParsedUrlQuery) =>
  fetchFromApi({
    method: 'POST',
    relativePath: `/api/v1/jumio_netverify/upload/error?${stringify(query)}`,
    throwError: true,
  })

export const postJumioSuccess = (query: ParsedUrlQuery) =>
  fetchFromApi({
    method: 'POST',
    relativePath: `/api/v1/jumio_netverify/upload/success?${stringify(query)}`,
    throwError: true,
  })

export const applyPromotionCodeInCart = async (
  body: ApplyPromotionRequest,
): Promise<ApplyPromotionResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: '/api/v2/promotions/apply',
    throwError: true,
  })

export const applyPromoCodeToMembership = async (
  subscriptionId: string,
  body: ApplyPromotionRequest,
): Promise<ApplyPromotionSubscriptionResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'POST',
    relativePath: `api/v4/subscription/${subscriptionId}/promotion_codes`,
    throwError: true,
  })

export const getMembershipPromoCode = async (
  subscriptionId: string,
): Promise<{ data: { attributes: ApplyPromotionRequest } }> =>
  fetchFromApi({
    method: 'GET',
    relativePath: `api/v4/subscription/${subscriptionId}/promotion_codes`,
  })

export const updateOneTimeAddOnsSubscription = async (
  body: UpdateOneTimeAddOnsSubscriptionRequest,
): Promise<Subscription> | null => {
  const response = await fetchFromApi({
    body: decamelizeKeys(body),
    method: 'PATCH',
    relativePath: '/api/v4/subscription/one_time_addon',
    throwError: true,
  })

  return parseJSONAPIPayload<Subscription>(response)
}

export const saveAdyenCreditCard = async (
  data: SaveAdyenCreditCardRequest,
): Promise<SaveAdyenCreditCardResponse> => (
  fetchFromApi({
    body: { data },
    method: 'POST',
    relativePath: '/api/juul_adyen/credit_cards',
  })
)

export const verifyAdyenPaymentDetails = async (data) => (
  fetchFromApi({
    body: data,
    method: 'POST',
    relativePath: '/api/juul_adyen/payment_details',
  })
)

export const getPromotionQuote = async (
  data: PromotionQuoteRequest,
): Promise<PromotionQuoteResponse> => (
  fetchFromApi({
    body: { ...decamelizeKeys(data) },
    method: 'POST',
    relativePath: '/api/v4/promotions/quote',
  })
)

export const fetchCsrfToken = async (): Promise<CsrfTokenResponse> => (
  fetchFromApi({
    method: 'GET',
    relativePath: '/api/v4/csrf_token',
  })
)

export const suppressSegment = async (): Promise<SuppressSegmentResponse> => (
  fetchFromApi({
    method: 'POST',
    relativePath: '/api/v4/segment/suppression',
  })
)

export const accountRecoverySendCode = async (
  data: AccountRecoverySendCodeRequest,
): Promise<AccountRecoverySendCodeResponse> => (
  fetchFromApi({
    body: { ...decamelizeKeys(data) },
    method: 'POST',
    relativePath: '/api/v4/account_recovery/send_code',
    throwError: true,
  })
)

export const accountRecoveryVerifyCode = async (
  data: AccountRecoveryVerifyCodeRequest,
): Promise<AccountRecoveryVerifyCodeResponse> => (
  fetchFromApi({
    body: { ...decamelizeKeys(data) },
    method: 'POST',
    relativePath: '/api/v4/account_recovery/verify_code',
    throwError: true,
  })
)

export const phoneVerificationRenewSendCode = async (
  data: PhoneVerificationRenewSendCodeRequest,
): Promise<PhoneVerificationRenewSendCodeResponse> =>
  fetchFromApi({
    body: decamelizeKeys(data),
    method: 'POST',
    relativePath: '/api/v4/phone_verification/renew/send_code',
    throwError: true,
  })

export const phoneVerificationRenewVerifyCode = async (
  data: PhoneVerificationRenewVerifyCodeRequest,
): Promise<PhoneVerificationRenewVerifyCodeResponse> =>
  fetchFromApi({
    body: decamelizeKeys(data),
    method: 'POST',
    relativePath: '/api/v4/phone_verification/renew/verify_code',
    throwError: true,
  })

export const estimateAge = async (
  data: EstimateAgeRequest,
): Promise<EstimateAgeResponse> =>
  fetchFromApi({
    body: data,
    method: 'POST',
    relativePath: '/api/v4/age_verification/age_estimations',
    throwError: true,
  })

export const skipAgeEstimation = async (
  data?: SkipAgeEstimationRequest,
): Promise<SkipAgeEstimationResponse> =>
  fetchFromApi({
    body: data,
    method: 'PUT',
    relativePath: '/api/v4/age_verification/age_estimations/skip',
    throwError: true,
  })

export const skipAgeEstimationBasedOnDOB = async (
  data?: SkipAgeEstimationBasedOnDOBRequest,
): Promise<SkipAgeEstimationResponse> =>
  fetchFromApi({
    body: data,
    method: 'POST',
    relativePath: '/api/v4/age_verification/age_estimations/skip_based_on_dob',
    throwError: true,
  })

export const fetchPurchaseLocations = async ():
  Promise<PurchaseLocationsResponse> =>
  fetchFromApi({
    method: 'GET',
    relativePath: '/api/v1/purchase_locations',
    throwError: true,
  })

export const createProductRegistration = async (
  data: ProductRegistrationRequest,
): Promise<ProductRegistrationResponse> =>
  fetchFromApi({
    body: decamelizeKeys(data),
    method: 'POST',
    relativePath: '/api/v1/product_registrations',
    throwError: true,
  })

export const verifySocure = async (
  data: VerifySocureRequest,
): Promise<VerifySocureResponse> =>
  fetchFromApi({
    body: decamelizeKeys(data),
    method: 'POST',
    relativePath: '/api/v4/age_verification/id_upload/socure/verify',
    throwError: true,
  })

export const startArcoscanAgeEstimationSession =
  async (): Promise<ArcoscanSessionResponse> => fetchFromApi({
    method: 'POST',
    relativePath: '/api/v4/age_verification/age_estimation/arcoscan/session',
    throwError: true,
  })

export const getArcoscanAgeEstimationSessionStatus =
  async (
    sessionUid: string,
  ): Promise<ArcoscanSessionStatusResponse> => fetchFromApi({
    method: 'GET',
    relativePath: `/api/v4/age_verification/age_estimation/arcoscan/session_status/${sessionUid}`,
    throwError: true,
  })

export const saveTraceabilityReport = async (
  data: TraceabilityReportRequest,
): Promise<TraceabilityReportResponse> =>
  fetchFromApi({
    body: decamelizeKeys(data),
    method: 'POST',
    relativePath: '/api/v1/traceability/reports',
    throwError: true,
  })

export const fetchDeliveryAddress =
  async (): Promise<DeliveryAddressResponse> =>
    fetchFromApi({
      relativePath: '/api/v4/delivery/addresses',
      throwError: true,
    })

export const setDeliveryAddress = async (
  body: DeliveryAddressRequest,
): Promise<DeliveryAddressResponse> =>
  fetchFromApi({
    body: decamelizeKeys(body),
    method: 'PUT',
    relativePath: '/api/v4/delivery/addresses',
    throwError: true,
  })

export const fetchGopuffMultipass = async (
  query: DeliveryAddressRequest,
): Promise<GopuffMultipassResponse> =>
  fetchFromApi({
    method: 'GET',
    relativePath: `/api/v4/delivery/multipass?${stringify(query)}`,
    throwError: true,
  })

export const getGlobalPages = async (): Promise<GetGlobalPagesResponse> =>
  fetchFromApi({
    method: 'GET',
    relativePath: '/api/v4/global_pages',
  })

export const getDoors = async (
  data: DoorsRequest,
  abortController?: AbortController,
): Promise<DoorsResponse> => {
  const { lat, lng, filters } = data
  const query = stringify({
    filters: JSON.stringify(decamelizeKeys(filters) || {}),
    lat,
    lng,
  })

  return fetchFromApi({
    method: 'GET',
    relativePath: `/api/v1/doors/doors?${query}`,
    signal: abortController?.signal,
    throwError: true,
  })
}

export const getCouponsForGeolocation = async (
  data: CouponsDoorsRequest &
    { couponSlug?: string | string[], userEmail: string },
): Promise<GetCouponsForGeolocationResponse> => {
  const { lat, lng, userEmail, couponSlug } = data
  const query = stringify(decamelizeKeys({
    lat,
    lng,
    userEmail,
  }))

  const slug = encodeURIComponent(
    couponSlug instanceof Array ? couponSlug[0] : couponSlug,
  )
  const relativePath = couponSlug ? `/api/v4/retail_coupons/${slug}?${query}` : `/api/v4/retail_coupons?${query}`

  return fetchFromApi({
    method: 'GET',
    relativePath: relativePath,
    throwError: true,
  })
}

export const getDoorsForCoupon = async (
  couponId: string,
  // used to ensure no doors >25 miles away shown, even if door
  // associated with the coupon
  geolocationData: CouponsDoorsRequest,
  couponSlug?: string | string[],
): Promise<GetDoorsForCouponResponse> => {
  const { lat, lng } = geolocationData
  const query = stringify({
    lat,
    lng,
  })

  const slug = encodeURIComponent(
    couponSlug instanceof Array ? couponSlug[0] : couponSlug,
  )
  const relativePath = couponSlug ? `/api/v4/retail_coupons/${couponId}/doors/${slug}?${query}` : `/api/v4/retail_coupons/${couponId}/doors?${query}`
  return fetchFromApi({
    method: 'GET',
    relativePath: relativePath,
    throwError: true,
  })
}

export const redeemCoupon = async (
  couponId: string,
  doorId: number,
  userEmail: string,
  redemptionChannel: string, // e.g. `/coupons` or `/coupons/kwik-trip`
) => fetchFromApi({
  body: decamelizeKeys({
    userEmail,
    redemptionChannel,
  }),
  method: 'POST',
  relativePath: `/api/v4/retail_coupons/${couponId}/doors/${doorId}`,
  throwError: true,
})

export const deleteAccount = async () =>
  fetchFromApi({
    method: 'DELETE',
    relativePath: '/api/v4/account',
    throwError: true,
  })

export const signInBusinessUser = async (body) => {
  const params = { spreeUser: body }

  return fetchFromApi({
    body: decamelizeKeys(params),
    method: 'POST',
    relativePath: '/api/v1/signin',
    throwError: true,
  })
}

export const acceptBusinessTermsAndConditions = async () =>
  fetchFromApi({
    method: 'PUT',
    relativePath: '/api/v1/terms_and_conditions/check',
    throwError: true,
  })

export const fetchBusinessStore = async (): Promise<BusinessStoreResponse> =>
  fetchFromApi({
    autoCamelizeKeys: false,
    method: 'GET',
    relativePath: '/api/v1/stores',
    throwError: true,
  })

export const fetchAgeVerificationVeratadToken
  = async (): Promise<FetchAgeVerificationVeratadTokenResponse> =>
    fetchFromApi({
      method: 'POST',
      relativePath: '/api/v4/age_verification/veratad/tokens',
      throwError: true,
    })

export const deleteFutureSubscriptionAddOns
  = async (): Promise<CartWithCartLineItems> =>
    fetchFromApi({
      method: 'DELETE',
      relativePath: '/api/v3/cart/future_subscription_add_ons',
      throwError: true,
    })
